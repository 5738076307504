'use client';
import Error from '@/components/App/Error/Error';
import useEnv from '@/hooks/useEnv';

const ErrorPage = ({ error }) => {
    const { isDevelopment } = useEnv();
    if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
    const subtitle = 'Please try later.';

    return <Error title='Something Went wrong!' subTitle={subtitle} />;
};

export default ErrorPage;
